import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';
import he from 'he';

export default class DesktopCards extends Component {
  state = {
    imageLoaded: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  handleImageLoaded = (image) => {
    if (image.includes('https')) {
      this.setState({ imageLoaded: true });
    } else {
      this.setState({ imageLoaded: false });
    }
  };

  render() {
    const s = require('./desktop.module.scss');
    const { imageLoaded } = this.state;
    const {
      images,
      date,
      title,
      category,
      author,
      button,
      blurb,
      post_blurb,
      location,
    } = this.props;
    const newImage = images && images.large;
    const decodedHtml = blurb
      ? he.decode(blurb || '')
      : he.decode(post_blurb || '');
    return (
      <Link
        href={
          location === '/category/catalogues'
            ? `/catalogue${button.link}`
            : `/article${button.link}`
        }
        passhref>
        <a className={s.Wrapper}>
          <div className={s.Container}>
            {images && (
              <div
                className={s.Image}
                style={{
                  background: `url(${images && images.large}) no-repeat center`,
                }}>
                <Image
                  src={newImage}
                  alt={title}
                  width='100%'
                  height={190}
                  style={{ opacity: 0 }}
                />
              </div>
            )}
            <div className={s.Content}>
              <div className={s.Date}>
                <span>{category}</span>
              </div>
              <div className={s.Title}>
                <h1>{title}</h1>
              </div>
              <div className={s.Category}>
                <div>{date}</div>
                <p>{author}</p>
              </div>
              {post_blurb && (
                <div
                  className={s.Paragraph}
                  dangerouslySetInnerHTML={{ __html: he.decode(post_blurb) }}
                />
              )}
              {blurb && (
                <div
                  className={s.Paragraph}
                  dangerouslySetInnerHTML={{ __html: decodedHtml }}></div>
              )}
            </div>
          </div>
        </a>
      </Link>
    );
  }
}

DesktopCards.propTypes = {
  images: PropTypes.object,
  date: PropTypes.string,
  title: PropTypes.string,
  category: PropTypes.array,
  author: PropTypes.string,
  postBlurb: PropTypes.string,
  blurb: PropTypes.string,
  button: PropTypes.object,
  location: PropTypes.string,
};
